:root {
  font-size: 10px;
}

html,
body,
div#root {
  width: 100%;
  height: 100%;
}

button,
a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

button,
iframe#vrPlayer {
  border: none;
}

iframe#vrPlayer {
  max-height: 75vh;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.navbar {
  position: fixed;
  display: flex;
  background-color: #0c2233;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 1.4rem 2.4rem;
  font-family: "Montserrat", sans-serif;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;
  letter-spacing: 0.5px;
}

.demoBtn {
  background-image: linear-gradient(
    to right,
    #ffce01 0%,
    #fae9a4 51%,
    #ffce01 100%
  );
  display: block;
  font-size: 2rem;
  padding: 1.2rem 3.5rem;
  background-size: 200% auto;
  color: #172c3d;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}

.demoBtn:hover {
  background-position: right center;
}

.navbar > a {
  text-decoration: none;
  color: #ffffff;
  margin-right: 6rem;
}

.jumbotron {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  background: url(../src/assets/bgImg.jpg) center center no-repeat,
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-size: cover;
  background-blend-mode: darken;
  justify-content: center;
}

.bgVideoContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.bgOverlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  z-index: 1;
}

.bgVideoContainer video {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.bottomJumboImg,
.topJumboImg {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 19rem;
}

.jumboTypography {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 27.8rem);
  max-width: 100%;
  z-index: 1;
}

.jumboTypography h1 {
  font-size: 5.6rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 0.7rem;
  font-family: "Montserrat", sans-serif;
}

.jumboTypography h2 {
  font-size: 3.2rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom: 3.2rem;
}

.yellowBar {
  height: 0.6rem;
  width: 60rem;
  background-color: #fdda48;
  border-radius: 0 0 0.7rem 0.7rem;
  margin: 0;
  padding: 0;
  margin-bottom: 10.5rem;
}

.topJumbo {
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 0;
}

.bottomJumbo {
  position: relative;
}

.revlityLogo,
.scrollIcn {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  padding: 0;
  margin: 0;
}

.scrollIcn {
  top: 10%;
  width: 2.4rem;
}

.revlityLogo {
  width: 17.6rem;
  top: 60%;
}

.bottomTypography {
  position: absolute;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
}

.bottomTypography p {
  font-size: 3.6rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 0;
  margin-bottom: 5.2rem;
  text-align: center;
}

.bottomTypography a {
  box-shadow: none;
  background: linear-gradient(#3b4750, #182d3d);
  height: auto;
  font-size: 2.4rem;
  padding: 1.8rem 4.4rem;
  border: none;
  border-radius: 3.2rem;
  color: #ffffff;
}

.dashboard {
  text-align: center;
  margin: 0 auto;
  width: calc(100% - 27.8rem);
  max-width: 70%;
}

.dashboard img {
  width: 50%;
  height: 50%;
}

.dashboard > h3 {
  font-size: 6.4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 15.5rem;
  margin-bottom: 4.4rem;
  text-align: center;
}

.dashboard > p {
  font-size: 3.2rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  margin: 0 auto;
  margin-bottom: 8.9rem;
  margin-top: 6.6rem;
  line-height: 5.2rem;
  text-align: left;
}

.playerBg {
  position: relative;
}

.playerLeftLines {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 200px;
  z-index: -100;
}

.playerLeftLines > div {
  position: absolute;
  border: 4px solid #ffce01;
}

.playerLeftLines .one {
  left: 50%;
  width: 50%;
  height: 12%;
  border-left: none;
  border-top: none;
}

.playerLeftLines .two {
  top: 12%;
  width: 50%;
  height: 30%;
  border-top: none;
  border-left: none;
}

.playerLeftLines .three {
  top: 30%;
  width: 30%;
  height: 100%;
  border-left: none;
  border-bottom: none;
}

.rightLines {
  position: absolute;
  right: 0;
  box-sizing: border-box;
  height: 100%;
  width: 200px;
  z-index: -100;
}

.rightLines > div {
  position: absolute;
  border: 4px solid #ffce01;
  right: 0;
}

.rightLines .one {
  top: 10%;
  width: 20%;
  height: 40%;
  border-right: none;
}

.rightLines .two {
  top: 30%;
  width: 40%;
  height: 50%;
  border-right: none;
}

.dashboardBg {
  position: relative;
}

.dashLeftLines {
  box-sizing: border-box;
  position: absolute;
  top: -30%;
  left: 0;
  height: 120%;
  width: 200px;
  z-index: -100;
}

.dashLeftLines > div {
  position: absolute;
  border: 4px solid #ffce01;
  left: 0;
}

.dashLeftLines .one {
  top: 0;
  width: 30%;
  height: 40%;
  border-left: none;
  border-top: none;
}

.dashLeftLines .two {
  top: 30%;
  width: 40%;
  height: 100%;
  border-left: none;
  border-bottom: none;
}

.player {
  padding: 0;
  margin: 0 auto;
  padding-top: 14.7rem;
  width: 90%;
  width: calc(100% - 27.8rem);
  max-width: 70%;
}

.playerTypo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6rem;
}

.player img {
  width: 50%;
  height: 50%;
}

.player h4 {
  /* max-width: 72.5rem; */
  font-size: 6.4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #001729;
  margin-top: 0;
  margin-bottom: 0;
}

.playerTypo p {
  font-size: 3.2rem;
  line-height: 5.2rem;
  font-family: "IBM Plex Sans", sans-serif;
  color: #001729;
  margin-bottom: 4.7rem;
  width: 60rem;
  margin-top: 6rem;
}

.playerFeatures {
  list-style-type: none;
  padding: 0;
  margin-top: 6.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
}

.playerFeatures li {
  position: relative;
  font-size: 2.4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #001729;
  margin-bottom: 7.4rem;
  padding-left: 10rem;
}

.playerFeatures li:before {
  content: "";
  display: block;
  height: 7rem;
  width: 7rem;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #fff3c2;
}

.playerFeatures li.lightIcn:before {
  background: url(../src/assets/lightIcn.svg) no-repeat center center,
    linear-gradient(180deg, #fdfcf7 0%, #fff9e2 100%);
  background-size: 50% 50%;
}

.playerFeatures li.navIcn:before {
  background: url(../src/assets/navIcn.svg) no-repeat center center,
    linear-gradient(180deg, #fdfcf7 0%, #fff9e2 100%);
  background-size: 50% 50%;
}

.playerFeatures li.gogglesIcn:before {
  background: url(../src/assets/gogglesIcn.svg) no-repeat center center,
    linear-gradient(180deg, #fdfcf7 0%, #fff9e2 100%);
  background-size: 50% 50%;
}

.getStarted {
  position: relative;
  width: 100%;
  background: linear-gradient(#f9f9f8, #ffffff);
}

.getStartImg {
  height: 60rem;
  width: 100%;
}

.getStartedTypography {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.getStartedTypography h5 {
  font-size: 3.6rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
}

.getStartedTypography p {
  font-size: 3.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.tablet {
  display: none;
}

.hello {
  padding-top: 22rem;
  padding-bottom: 10rem;
  text-align: center;
  background-color: #f9f9f8;
  font-family: "Montserrat", sans-serif;
}

.hello h1 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 6.4rem;
}

.hello h2 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 4.8rem;
}

.hello h1 span {
  color: #ffce01;
}

.footer {
  position: relative;
  background-color: #f9f9f8;
}

.footer img {
  height: 10rem;
  width: 100%;
}

.footer .text {
  position: absolute;
  display: flex;
  width: calc(100% - 27.3rem);
  justify-content: space-between;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  font-size: 1.8rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.hello p {
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0 auto;
  font-size: 3.6rem;
  margin-top: 2.6rem;
}

.hello form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 6.7rem;
}

.hello form > div {
  margin-bottom: 4rem;
}

.hello label {
  display: block;
  text-align: left;
  font-size: 1.6rem;
  margin-bottom: 0.4rem;
}

.hello form input,
.custom-select__control,
.hello form textarea {
  width: 47.7rem;
  font-size: 2rem;
  padding: 2rem;
  box-sizing: border-box;
  outline: none;
  border-radius: 0 !important;
  box-shadow: none;
  border: 1px solid #bac0c5;
  border-style: solid;
  -webkit-appearance: none;
}

.hello form button {
  font-size: 2.4rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  width: 27.2rem;
  padding: 1.2rem 0;
  border-radius: 4rem;
  color: white;
  background: linear-gradient(#3b4750, #182d3d);
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.custom-select__indicator-separator {
  display: none;
}

.custom-select__menu {
  font-size: 2rem;
}

.helloList {
  display: flex;
  width: calc(100% - 27.8rem);
  margin: 0 auto;
  justify-content: space-between;
  padding-top: 16rem;
  padding-bottom: 15rem;
  max-width: 70%;
}

.helloIcn {
  width: 18.8rem;
  height: 18.8rem;
  background-color: transparent;
}

.helloIcn img {
  width: inherit;
  height: inherit;
  object-fit: contain;
}

.helloListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.helloListItem a {
  display: inline-block;
  text-decoration: none;
  font-size: 2.4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #001729;
  margin-top: 4.4rem;
  border: 0.2rem solid #001729;
  border-radius: 3.6rem;
  padding: 1.5rem 3rem;
  transition: 0.3s;
}

.helloListItem a:hover {
  color: #ffce01;
  background-color: #001729;
}

.helloListItem:nth-child(odd) {
  margin-top: 8.8rem;
}

.helloHeader {
  position: relative;
}

.backBtn {
  position: absolute;
  border: 2px solid black;
  left: 10%;
  top: 50%;
  outline: none;
  background-color: transparent;
  padding: 2rem;
  margin: 0;
  transform: translateY(-50%);
}

.backBtn img {
  display: block;
  padding: 0;
  margin: 0;
}

.privacy {
  width: 60vw;
  margin: 0 auto;
  font-size: 3rem;
  color: rgb(78, 78, 78);
}

.thanks {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 60vh;
  font-family: "Montserrat", sans-serif;
}

.thanks h1 {
  font-size: 4.4rem;
  color: #ffce01;
  text-shadow: #182d3d;
}

.thanks p {
  font-size: 3rem;
  font-weight: 500;
}

/* Surface Pro @ 1440px -------------------------------------------------------------- */
@media (max-width: 1440px) {
  :root {
    font-size: 8.75px;
  }

  .jumboTypography h1 {
    font-size: 5.49rem;
  }

  .jumboTypography h2,
  .playerTypo p,
  .dashboard > p {
    font-size: 2.74rem;
  }

  .player {
    width: 89.3rem;
    max-width: 100%;
  }

  .playerTypo {
    flex-direction: column;
  }

  .playerTypo p {
    width: auto;
  }

  .player img {
    width: 100%;
    height: 100%;
  }

  .playerFeatures {
    flex-direction: row;
    padding-right: 6rem;
  }

  .dashboard {
    width: 89.3rem;
    max-width: 100%;
  }

  .dashboard img {
    width: 100%;
    height: 100%;
  }

  .helloList {
    max-width: 100%;
  }

  .helloIcn {
    width: 13.72rem;
    height: 13.72rem;
  }

  .playerLeftLines .one {
    height: 6%;
  }
  .playerLeftLines .two {
    top: 6%;
  }

  .hello {
    padding-top: 15rem;
    padding-bottom: 5rem;
  }
}

/* Screen @ 1366px -------------------------------------------------------------- */
@media (max-width: 1366px) {
  .player,
  .dashboard,
  .hello p {
    width: 83.72rem;
  }

  .playerLeftLines,
  .dashLeftLines,
  .rightLines {
    width: 220px;
  }
}

/* Screen @ 1280px -------------------------------------------------------------- */
@media (max-width: 1280px) {
  .player,
  .dashboard,
  .hello p {
    width: 77.21rem;
  }

  .playerLeftLines,
  .dashLeftLines,
  .rightLines {
    width: 250px;
  }
}

/* Android Tablet @ 1024px -------------------------------------------------------------- */
@media (max-width: 1024px) {
  :root {
    font-size: 6.2px;
  }

  .navbar {
    font-size: 2.58rem;
    padding: 2.26rem;
  }

  .demoBtn,
  .hello form button {
    width: auto;
    font-size: 3rem;
    padding: 1.8rem 4rem;
  }

  .player,
  .dashboard {
    width: 76rem;
    max-width: 80%;
  }

  .jumboTypography {
    width: 109.35rem;
  }

  .jumboTypography h1 {
    font-size: 6.45rem;
  }

  .playerFeatures {
    flex-direction: column;
  }

  .hello {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .hello form input,
  .custom-select__control,
  .hello form textarea {
    width: 76rem;
    font-size: 3.2rem;
    padding: 3rem;
  }

  .hello label {
    font-size: 3rem;
  }

  .hello p {
    width: 76rem;
  }

  .bottomTypography a {
    font-size: 4rem;
    padding: 1.8rem 4.4rem;
    border-radius: 5rem;
  }

  .playerLeftLines,
  .dashLeftLines,
  .rightLines {
    width: 200px;
  }

  .playerLeftLines > div,
  .dashLeftLines > div,
  .rightLines > div {
    border: 3px solid #ffce01;
  }
}
/* For devices @ 700px -------------------------------------------------------------- */
@media (max-width: 700px) {
  .playerLeftLines,
  .dashLeftLines,
  .rightLines {
    width: 100px;
  }

  .playerLeftLines > div,
  .dashLeftLines > div,
  .rightLines > div {
    border: 3px solid #ffce01;
  }
}

/* Nexus 6 like devices @ 600px -------------------------------------------------------------- */
@media (max-width: 600px) {
  :root {
    font-size: 4.06px;
  }

  .demoBtn,
  .hello form button {
    font-size: 4rem;
    padding: 1.8rem 5rem;
  }

  .navbar {
    display: none;
  }

  .revlityLogo {
    top: 50%;
  }

  .jumboTypography h1 {
    font-size: 7.39rem;
  }

  .playerFeatures {
    align-items: center;
    padding: 0;
  }

  .playerFeatures li {
    padding-left: 0;
    padding-top: 10rem;
    margin-top: 10rem;
    font-size: 4.43rem;
  }

  .playerFeatures li::before {
    padding-left: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15.27rem;
    height: 15.27rem;
  }

  .helloList {
    flex-direction: column;

  }

  .helloListItem:nth-child(odd) {
    margin-top: 0;
  }

  .helloListItem:not(:last-child) {
    margin-bottom: 19.4rem;
  }

  .helloIcn {
    width: 18.72rem;
    height: 18.72rem;
  }

  .helloListItem a {
    margin-top: 6.9rem;
    font-size: 4rem;
  }

  .footer img {
    height: 26rem;
  }

  .footer .text {
    flex-wrap: wrap;
    top: 75%;
  }

  .footer .madeBy {
    display: inline-block;
    font-size: 3.45rem;
    font-weight: 600;
    text-align: center;
    order: 1;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .footer .copyRight,
  .footer .terms {
    text-align: center;
    order: 2;
    flex: 1;
    font-size: 3rem;
  }

  .footer .terms {
    order: 3;
  }

  .hello form input,
  .custom-select__control,
  .hello form textarea {
    width: calc(100vw - 13.93rem);
    font-size: 3.98rem;
    padding: 4rem;
  }

  .hello label {
    font-size: 3.5rem;
  }

  .hello p {
    width: calc(100vw - 13.93rem);
  }

  .backBtn {
    left: 6.97rem;
  }

  .playerLeftLines,
  .dashLeftLines,
  .rightLines {
    width: 180px;
  }

  .playerLeftLines > div,
  .dashLeftLines > div,
  .rightLines > div {
    border: 2px solid #ffce01;
  }

  .playerLeftLines .one {
    height: 4%;
  }

  .playerLeftLines .two {
    top: 4%;
  }
}

/* For devices @ 550px -------------------------------------------------------------- */
@media (max-width: 550px) {
  .playerLeftLines,
  .dashLeftLines,
  .rightLines {
    width: 130px;
  }
}
/* For devices @ 450px -------------------------------------------------------------- */
@media (max-width: 450px) {
  .playerLeftLines,
  .dashLeftLines,
  .rightLines {
    width: 90px;
  }
}

/* iPhoneX @ 375px -------------------------------------------------------------- */
@media (max-width: 375px) {
  :root {
    font-size: 4.06px;
  }

  .navbar {
    display: none;
  }

  .revlityLogo {
    top: 50%;
  }

  .jumboTypography h1 {
    font-size: 7.39rem;
  }

  .playerFeatures {
    align-items: center;
  }

  .playerFeatures li {
    padding-left: 0;
    padding-top: 10rem;
    margin-top: 10rem;
    font-size: 4.43rem;
  }

  .playerFeatures li::before {
    padding-left: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15.27rem;
    height: 15.27rem;
  }

  .helloList {
    flex-direction: column;
  }

  .helloListItem:nth-child(odd) {
    margin-top: 0;
  }

  .helloListItem:not(:last-child) {
    margin-bottom: 19.4rem;
  }

  .helloIcn {
    width: 18.72rem;
    height: 18.72rem;
  }

  .helloListItem a {
    margin-top: 6.9rem;
    font-size: 4rem;
  }

  .footer img {
    height: 26rem;
  }

  .footer .text {
    flex-wrap: wrap;
    top: 75%;
  }

  .footer .madeBy {
    display: inline-block;
    font-size: 3.45rem;
    font-weight: 600;
    text-align: center;
    order: 1;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .footer .copyRight,
  .footer .terms {
    text-align: center;
    order: 2;
    flex: 1;
    font-size: 3rem;
  }

  .footer .terms {
    order: 3;
  }

  .hello form input,
  .custom-select__control,
  .hello form textarea {
    width: calc(100vw - 13.93rem);
    font-size: 3.98rem;
    padding: 4rem;
  }

  .hello label {
    font-size: 3.5rem;
  }

  .hello p {
    width: 64.18rem;
  }

  .playerLeftLines,
  .dashLeftLines,
  .rightLines {
    width: 50px;
  }
}

/* iPhone5 @ 320px -------------------------------------------------------------- */
@media (max-width: 320px) {
  :root {
    font-size: 3.2px;
  }

  .playerTypo p,
  .jumboTypography h2,
  .dashboard p {
    font-size: 4.5rem;
    line-height: 1.7;
  }

  .bottomTypography p {
    font-size: 5rem;
    width: 74.38rem;
  }
}

/* Video Specific Stuffs -------------------------------------------------------------- */
@media (min-aspect-ratio: 16/9) {
  .bgVideoContainer video {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .bgVideoContainer video {
    width: auto;
    height: 100%;
  }
}

@media (max-width: 601px) {
  .bgVideoContainer video {
    width: 100%;
    height: auto;
  }
}

/* Landscape orientation  -------------------------------------------------------------- */
@media (max-width: 700px) and (orientation: landscape) {
  :root {
    font-size: 4px;
  }
}
